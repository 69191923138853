import VideoModal from '~/components/common/video-modal'

export default {
  components: {
    VideoModal
  },
  data: () => ({
    id: ''
  }),
  methods: {
    onVideo(id) {
      this.id = id
      this.$modal.show('video')
    }
  }
}
