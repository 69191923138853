<template>
  <div class="video-modal">
    <modal
      :max-width="960"
      :adaptive="true"
      :pivot-x="0.5"
      :pivot-y="0.5"
      @opened="open = true"
      @closed="open = false"
      width="960"
      height="auto"
      name="video"
      class="modal"
    >
      <iframe
        id="video-iframe"
        :src="`${url}/${id}`"
        :height="getVideoHeight"
        width="100%"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    url: 'https://www.youtube.com/embed/',
    open: false,
    width: window.innerWidth
  }),
  computed: {
    getVideoHeight() {
      let height = 530
      if (this.open) {
        const iframe = document.getElementById('video-iframe')
        height = iframe.getBoundingClientRect().width * 0.55
        window.addEventListener('resize', this.resize)
      }
      return height
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      const iframe = document.getElementById('video-iframe')
      if (!iframe) {
        window.removeEventListener('resize', this.resize)
      } else if (window.innerWidth !== this.width) {
        iframe.height = iframe.getBoundingClientRect().width * 0.55
      }
    }
  }
}
</script>
