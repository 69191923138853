<template>
  <div :style="{ 'background-color': bgColor || 'none' }" class="section">
    <div v-if="setContainer" class="section__container">
      <slot></slot>
    </div>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: ''
    },
    setContainer: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  &__container {
    max-width: 1024px;
    margin: 0 auto;
  }
}
</style>
