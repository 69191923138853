<template>
  <div :class="$style['voc-card']" class="voc-card">
    <div :class="$style['voc-card__company']">{{ data.company }}</div>
    <div :class="$style['voc-card__user']">{{ data.user }}님</div>
    <div :class="$style['voc-card__tags']">{{ data.type }}</div>
    <p :class="$style['voc-card__text']">{{ data.pros }}</p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.voc-card {
  $card: '.voc-card';
  position: relative;
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  box-sizing: border-box;

  @include av-mq(lap-and-up) {
    padding: 1.5rem;
  }

  #{ $card }__company,
  #{ $card }__text {
    word-break: keep-all;
  }

  #{ $card }__company {
    position: relative;
    width: calc(100% - 60px);
    margin-bottom: 0.25rem;
    line-height: 1.3;
    font-size: 1rem;
    font-weight: bold;

    @include av-mq(lap-and-up) {
      font-size: 1.25rem;
    }

    &:before {
      position: absolute;
      top: -1rem;
      left: 0;
      // content: '';
      width: 24px;
      height: 2px;
      display: block;
      background-color: #1f75e8;
    }
  }

  #{ $card }__user {
    position: absolute;
    top: 22px;
    right: 1rem;
    font-size: 13px;

    @include av-mq(lap-and-up) {
      top: 30px;
      right: 1.5rem;
      font-size: 15px;
    }
  }

  #{ $card }__tags {
    display: inline-block;
    padding: 1px 8px;
    color: rgba(0, 0, 0, 0.7);
    background-color: rgba(0, 0, 0, 0.05);
    overflow: hidden;
    border-radius: 8px;
    font-size: 13px;
    line-height: 1.3rem;

    @include av-mq(lap-and-up) {
      font-size: 14px;
    }
  }

  #{ $card }__text {
    position: relative;
    padding-top: 1rem;
    font-size: 13px;
    line-height: 1.63;
    color: #202124;

    @include av-mq(lap-and-up) {
      font-size: 14px;
    }
  }
}
</style>
